<template>
  <div class="building-contacts-page">
    <m-page-title title="Building Contacts" address> </m-page-title>
    <div class="content-border g-radius-4">
      <div class="content g-scroll-style">
        <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item
            title="Owners Corporation Company Details
"
            name="1"
          >
            <div class="items">
              <span class="label">Owners Corporation Company Name</span>
              <span class="value">{{ detail.ocCompanyName }}</span>
            </div>
            <div class="items">
              <span class="label">OC Number</span>
              <span class="value">{{ detail.ocNumber }}</span>
            </div>
            <div class="items">
              <span class="label">Owners Corporation Contact Name</span>
              <span class="value">{{ detail.ocName }}</span>
            </div>
            <div class="items">
              <span class="label">Email</span>
              <span class="value">{{ detail.ocEmail }}</span>
            </div>
            <div class="items">
              <span class="label">Company Phone Number</span>
              <span class="value">{{ detail.ocPhoneNumber }}</span>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Building Contact Details
" name="2">
            <div class="items">
              <span class="label">Building Contact Title</span>
              <span class="value">{{ detail.title }}</span>
            </div>
            <div class="items">
              <span class="label">Building Contact Name</span>
              <span class="value">{{ detail.name }}</span>
            </div>
            <div class="items">
              <span class="label">Email</span>
              <span class="value">{{ detail.email }}</span>
            </div>
            <div class="items">
              <span class="label">Phone</span>
              <span class="value">{{ detail.phone }}</span>
            </div>
            <div class="items">
              <span class="label">Notes</span>
              <span class="value">{{ detail.note }}</span>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <m-to-dashboard class="btn" />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BuildingContacts",
  data() {
    return {
      activeNames: "0",
      detail: {},
    };
  },
  computed: {
    ...mapState("apartment", ["room", "apartment"]),
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      let url = `${this.$api.getContact(
        this.room.apartmentId
      )}?apartmentUnitId=${this.room.apartmentUnitId}`;
      this.$axios.get(url).then((res) => {
        if (res.code === "1000") {
          this.detail = res.data.apartmentContact;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss" src="./scss.scss"></style>
